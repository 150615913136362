.assign-goal-form {
  width: 100%;
  @include flex(column, flex-start, flex-start);

  &__field {
    width: 100%;

    .select,
    .select-active {
      height: 4.8rem;
      min-width: 100%;
    }

    &+& {
      margin-top: 3.2rem;
    }

    .label {
      margin-bottom: .8rem;
    }

    &_month {

      .select,
      .select-active {
        height: 4.8rem;
        min-width: 24.8rem;
        max-width: 24.8rem;
      }
    }
  }

  &__prompt {
    @include flex(column, flex-start, flex-start);

    span {
      color: $dark_gray;
    }
  }

  &__link {
    margin-top: .8rem;
    color: $main;
    transition: all .2s ease;

    &:hover {
      color: $yellow;
      text-decoration: underline;
    }
  }
}

.assign-goal-form-skeleton {
  &__input {
    width: 100%;

    &_month {
      max-width: 24.8rem;
    }

    .input-skeleton {
      width: 100%;
    }
  }

  &__link {
    @extend .assign-goal-form__link;
    cursor: pointer;
  }
}