h1 {
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 4rem;
}

h2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 4.8rem;
}

h3 {
  font-weight: 800;
  font-size: 2.2rem;
  line-height: 2.6rem;
}

h4 {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.7rem;
}

p {
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.quote {
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 4.8rem;
}

.text-main {
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.text-large {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.text-bold {
  font-weight: 700;
}

.text-semi-bold {
  font-weight: 600;
}

.text-medium {
  font-weight: 500;
}

.label {
  color: $dark_gray;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  display: inline-block;

  &>span {
    margin-bottom: .8rem;
    display: inline-block;
  }
}

.main-title {
  margin-bottom: 4.8rem;
}