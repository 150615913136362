.primary-button,
.primary-button_disabled {
  padding: .8rem 2rem;
  background-color: $yellow;
  border-radius: $button_radius;
  border: none;
  display: inline-block;
  font-weight: 500;
  color: $main;
  font-size: 1.4rem;
  line-height: 2.4rem;
  outline: none;
  cursor: pointer;
  transition: all .2s;
  border: 2px solid transparent;
  font-family: $raleway;

  &:hover {
    opacity: .8;
  }

  &:active {
    opacity: 1;
  }

  &:disabled,
  &_disabled {
    background-color: $light_yellow;
    color: $light_brown;
    cursor: initial;
    opacity: 0.8;
    pointer-events: none;
  }

  &:disabled:hover,
  &_disabled {
    opacity: 1;
    opacity: 0.8;
    pointer-events: none;
  }

  &__gray {
    @extend .primary-button;
    background-color: $light_gray;
    color: $main;

    &:hover {
      background-color: darken($light_gray, 10);
    }
  }
}