.meeting-card {
  background-color: $main;
  border-radius: 3.2rem;
  padding: 2.4rem 1.6rem 2.8rem 2.8rem;
  max-width: 32rem;
  border: 1px solid $main;

  &__text {
    color: $white;
    margin-bottom: .9rem;
  }

  &__date {
    color: $yellow;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 500;
    margin-bottom: 2.2rem;
    max-width: 18rem;
  }

  &__reviewer {
    display: flex;
    align-items: center;

    img {
      object-fit: cover;
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 50%;
      margin-right: .4rem;
    }

    p {
      color: $white;
      font-weight: 500;
    }
  }

  &_disabled {
    @extend .meeting-card;
    background-color: transparent;
    border: 1px solid $light_gray_3;

    .meeting-card {
      &__text {
        color: $dark_gray;
      }

      &__date {
        color: $dark_gray;
      }

      &__reviewer {
        p {
          color: $dark_gray;
        }
      }
    }
  }

  &__loading-title {
    margin-bottom: 2.2rem;
  }

  &__loading-date {
    margin-bottom: 1rem;
    display: block!important;
  }

  &__loading-date_short {
    margin-bottom: 2.7rem;
  }

  &__loading-photo {
    margin-right: 1.2rem;
  }
}