.completion-note-wrapper {
  @extend .text-main;

  &-form {
    display: grid;
    min-width: 52.8rem;
    margin-top: 2.4rem - 3.2rem;

    &__field {
      &_goal-type {
        @include flex(column, flex-start, flex-start);

        span {
          &:first-child {
            &::first-letter {
              text-transform: uppercase;
            }
          }
        }

        span+span {
          margin-top: .2rem;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }

      &_note {
        min-height: 19.8rem;
        display: flex;
      }

      &-prompt {
        margin-top: 1.2rem !important;
      }

      &_inputs {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3.2rem;
      }

      &+& {
        margin-top: 3.6rem;
      }

      &:last-child {
        span {
          display: inline-block;
          font-size: 13px;
          line-height: 20px;
          color: $dark_gray;
          max-width: 22.2rem;
          margin-left: 2.9rem;
          margin-top: auto;
          vertical-align: bottom;
        }
      }
    }

    &__input {
      position: relative;

      &_file {
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          width: calc(100% - 2rem);
        }
      }

      &-icon {
        position: absolute;
        top: 50%;
        right: 1.4rem;
        transform: translateY(-50%);
      }

      &-prompt {
        font-size: 13px;
        line-height: 20px;
        color: $dark_gray;
      }
    }
  }
}