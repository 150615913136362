 .nav-tabs {
   display: flex;
   align-items: center;
   justify-content: flex-start;

   &__tab {
     &+& {
       margin-left: 1.6rem;
     }

     &:first-child {
       @extend .primary-button;
       border-color: $yellow;
     }
   }
 }