.company-logo,
.company-logo--black {
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: $white;

  .company-logo {
    color: $white;
  }

  span {
    font-weight: 800;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    background-color: $yellow;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}

.company-logo--black {
  color: $main;
}