.fade {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity .3s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity .3s;
  }
}

.slide {
  &-enter {
    opacity: 0;
    transform: translateX(-200%);
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all .3s;
  }

  &-exit {
    transform: translateX(0);
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transform: translateX(-200%);
    transition: all .3s;
  }
}