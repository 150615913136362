.multiple-select {
  max-width: 30rem;
  min-width: 24.8rem;

  &__in {
    position: relative;
    height: 100%;
    min-height: $input_max_height;

    &+& {
      margin-top: 1.6rem;
    }

    .gray-circle-close-button {
      position: absolute;
      content: '';
      top: 1.2rem;
      right: -4.4rem;
    }

    .select,
    .select-active {
      min-height: $input_max_height;
    }

    .select-active {
      z-index: 5;
    }
  }
}