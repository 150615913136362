.group-card {
  background-color: $light_gray;
  border-radius: 3.2rem;
  padding: 2.4rem 3.2rem 2.8rem;
  height: 17.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 80rem;
  border: 1px solid transparent;
  transition: all .2s;

  &:hover {
    border: 1px solid $gray;
  }

  &__title {
    color: $main;
    max-width: 70%;
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 500;
  }

  &__title-wrapper_loading {
    display: flex;
    flex-direction: column;
  }

  &__title_loading {
    margin-bottom: 2.2rem;
  }

  &__progresses_loading {
    display: flex;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header_loading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.2rem;
  }

  &__users {
    display: flex;

    img {
      height: 3.4rem;
      width: 3.4rem;
      border-radius: 50%;
      margin-right: -1rem;
    }
  }

  &__user_loading {
    margin-right: -1rem;
    border: 2px solid $light_gray;
  }

  &__user_loading {
    display: flex;
  }

  &__other-users {
    height: 3.4rem;
    width: 3.4rem;
    border-radius: 50%;
    background-color: $dark_gray;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__progress-description {
    color: $main;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__total-progress {
    font-weight: 600;
  }

  &__weekly-progress {
    color: $yellow;
  }

  &__weekly-progress_red {
    color: $accent;
  }
}

.card-brown, .card-pink, .card-purple, .card-violet, .card-light_blue, .card-slate_gray {
  .group-card__title {
    color: $white;
  }
}