.goals-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @include main-width;

  .remove-icon {
    position: relative;
    margin-top: 1.6rem;

    &:hover {
      background-color: rgba(250, 105, 24, .2);

      .remove-icon {
        stroke: $accent;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $gray;
      transform: translateY(-.8rem) scaleX(1.2);
    }
  }

  &__empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 2.4rem;
      line-height: 3.6rem;
      color: $dark_gray;
      font-weight: 500;
      max-width: 39.6rem;
      text-align: center;
      margin-bottom: 3.2rem;
    }

    svg {
      margin-bottom: 3.2rem;
    }
  }

  .goals-header__search {
    position: absolute;
    left: 46rem;
    z-index: 5;
  }

  &__content {
    width: 100%;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;
  }

  &__reset {
    color: $accent;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 3.2rem;
  }

  &__title_wrapped {
    margin-bottom: 0;
    margin-right: 2.4rem;
  }

  &__goals-list-item {
    margin-bottom: 3.2rem;

    &:last-of-type {
      margin-bottom: 4.8rem;
    }
  }

  .goals-header {
    margin-bottom: 4.8rem;
  }
}

.goals-list {
  max-width: 118.4rem;
  width: calc(100vw - #{$sidebar_width} - 12.8rem);

  .progress-bar__external {
    background-color: $yellow;
  }

  @media screen and #{$bp-notebook} {
    width: calc(100vw - 12.8rem);
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;
  }

  &__reset {
    color: $accent;
    cursor: pointer;
  }

  &__title_wrapped {
    margin-bottom: 0;
    margin-right: 2.4rem;
  }

  &__selectors {
    margin-bottom: 3.2rem;
  }

  &__header-item {
    display: flex;
    align-items: center;

    p {
      cursor: pointer;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }

  &__header-item_active {
    @extend .goals-list__header-item;
    color: $main;
  }

  &__header-arrow {
    margin-left: .4rem;
  }

  &__header-arrow_active {
    .down-icon {
      fill: $main;
    }

    margin-left: .4rem;
  }

  &__header-item--hidden {
    display: none;
  }

  &__header {
    display: flex;
    color: $dark_gray;
    font-size: 1.2rem;
    line-height: 1.7rem;
    position: relative;
    padding-bottom: 2.4rem;
    margin-bottom: 3.2rem;
    justify-content: space-between;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $gray;
      height: 1px;
    }
  }

  &__title {
    margin-bottom: 2.3rem;
  }

  &__rows {
    margin-bottom: 4.4rem;

    a {
      color: $main;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.9rem;
    justify-content: space-between;
  }

  &__goal {
    p {
      display: inline;
      word-wrap: break-word;
      transition: all .2s;

      &:hover {
        color: $yellow;
      }
    }
  }

  &__progress {
    display: flex;
    align-items: center;

    &-number {
      margin-left: 1.6rem;
      padding-right: 25%;
    }

    &-number_completed {
      margin-left: 1.6rem;
      padding-right: 22%;
    }
  }

  &__week-progress-text {
    color: $yellow;
  }

  &__week-progress-text--negative {
    color: $dark_gray;
  }
}

.goals-header {
  position: relative;
  width: 100%;

  &__tabs {
    display: flex;

    h1 {
      margin-right: 3.1rem;
    }
  }

  &__date {
    margin-right: 1.6rem;
  }

  &__groups {
    margin-right: 1.6rem;
    width: 20rem;
  }

  &__sort {
    margin-right: 1.6rem;
    width: 4rem;
  }

  &__selectors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.8rem;
    position: relative;
  }

  &__toggler {
    position: absolute;
    right: 0;
  }

  &__toggler_static {
    margin-left: 8.8rem;
  }
}