.goals-info-list {
  margin-top: 3.8rem;

  .progress-bar__external {
    background-color: $yellow;
  }

  a {
    color: $main;
  }

  &__header {
    display: flex;
    color: $dark_gray;
    font-size: 1.2rem;
    line-height: 1.7rem;
    position: relative;
    padding-bottom: 2.4rem;
    margin-bottom: 3.2rem;
    justify-content: space-between;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $gray;
      height: 1px;
    }
  }

  &__header_static {
    display: flex;
    color: $dark_gray;
    font-size: 1.2rem;
    line-height: 1.7rem;
    position: relative;
    padding-bottom: 2.4rem;
    margin-bottom: 3.2rem;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $gray;
      height: 1px;
    }
  }

  &__header-item {
    display: flex;
    align-items: center;

    p {
      cursor: pointer;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }

  &__header-item_active {
    @extend .goals-info-list__header-item;
    color: $main;

    .down-icon {
      fill: $main;
    }
  }

  &__header-arrow {
    margin-left: .4rem;
  }

  &__header-arrow_active {
    margin-left: .4rem;
  }

  &__header-item--hidden {
    display: none;
  }

  &__rows {
    margin-bottom: 4.4rem;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.9rem;
    justify-content: space-between;
  }

  &__row_static {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.9rem;
  }

  &__who {
    p {
      display: inline;
      transition: all .2s;

      &:hover {
        color: $yellow;
      }
    }
  }

  &__progress {
    display: flex;
    align-items: center;
  }

  &__week-progress-text {
    color: $yellow;
  }

  &__week-progress-text--negative {
    color: $dark_gray;
  }
}

.goal-info-sidebar-holder {
  height: 100vh;
  position: fixed;
  right: 0;
  background-color: $light_gray_2;
  top: 0;

  @media screen and (min-width: 1650px){
    width: 25%;
  }
}

.goal-info-sidebar {
  width: 38.4rem;
  height: 100%;
  padding: 4.7rem 3.2rem 0;

  .link-icon {
    transition: all .2s;
  }

  &__buttons {
    height: 8.5rem;
    padding-bottom: 3.5rem;
    border-bottom: 1px solid $gray;
    margin-bottom: 5rem;

    button:first-of-type {
      margin-right: 1.6rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 3.6rem;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 9.6rem;
    border-radius: 1.6rem;
    object-fit: cover;
  }

  &__item:last-of-type {
    margin-bottom: 4.9rem;
  }

  &__user-name {
    transition: all .2s;
  }

  &__user-link {
    display: flex;
    align-items: center;

    &:hover {
      .goal-info-sidebar__user-name {
        color: $yellow;
      }
    }
  }

  &__group {
    transition: all .2s;

    &:hover {
      color: $yellow;
    }
  }

  &__key {
    width: 11rem;
    margin-right: 2.9rem;
    color: $dark_gray;
    display: inline-block;
  }

  &__link {
    transition: all .2s;
    display: flex;
    align-items: center;

    &:hover {
      color: $yellow;

      .link-icon {
        fill: $yellow;
      }
    }
  }

  &__value {
    display: flex;
    align-items: center;
    

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      max-width: 11rem;
      overflow: hidden;
    }

    svg {
      margin-right: 1.2rem;
    }

    span {
      text-transform: capitalize;
    }
  }

  &__assignee {
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 50%;
    margin-right: 1.2rem;
  }
}

.goal-info {
  @include main-width;;

  .toggler {
    margin-top: 4.8rem;
    max-width: 28.8rem;
    width: fit-content;
  }
}

.goal-info-progresses-empty-state {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 7.4rem;

  p {
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: $dark_gray;
    text-align: center;
    max-width: 34.3rem;
    font-weight: 500;
  }
}

.goal-info-goal-description {
  position: relative;

  &__title {
    margin-bottom: 3.2rem;
  }

  .progress-block+.progress-block_white {
    margin-top: 2.4rem;
  }

  &__title_done {
    @extend .goal-info-goal-description__title;
    color: $green_text;
  }

  &__description {
    position: relative;
    margin-bottom: 5.1rem;
  }

  &__check-icon {
    position: absolute;
    top: 1rem;
    left: -.8rem;
    transform: translate(-100%, 0);
  }
}

.goal-info-completion-notes {
  margin-top: 3.2rem;

  .completion-note:not(:last-of-type) {
    margin-bottom: 3.2rem;
  }
}

.goal-info-personal-goal-empty-state {
  display: flex;
  justify-content: center;
  margin-top: 6rem;

  div {
    height: 43rem;
    width: 32rem;
    background-position: center;
    background-repeat: no-repeat;
  }
}