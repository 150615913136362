.my-progress {

  &__main {
    @include main-width;;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.my-progress-header {
  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.8rem;
    flex-wrap: wrap;

    div {
      display: flex;
      align-items: center;
    }

    p {
      font-weight: 400;
      color: $dark_gray;
      margin-right: 2vw;
    }
  }

  &__selectors {
    display: flex;
    margin-bottom: 3.2rem;
  }

  &__select-by-date {
    margin-right: 2.4rem;
  }
}

.my-progress-goals {
  width: 100%;

  &__card:not(:last-of-type) {
    margin-bottom: 3.2rem;
  }
}

.my-progress-sidebar-holder {
  background-color: $light_gray_2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;

  @media screen and (min-width: 1650px){
    width: 25%;
  }
}

.my-progress-sidebar {
  width: 38.4rem;
  overflow-y: scroll;
  padding: 5.7rem 3.2rem 7.8rem 3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__meetings {
    background-color: transparent;
    border-radius: 32px;
    border: 1px solid $light_gray_3;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 19rem;
    height: 19rem;
    color: $dark_gray;
    margin-bottom: 3.2rem;
    width: 32rem;

    p {
      max-width: 15.3rem;
    }
  }

  &__title {
    font-weight: 700;
    margin-bottom: .8rem;
    align-self: flex-start;
  }

  &__completed {
    color: $dark_gray;
    margin-bottom: 6rem;
    align-self: flex-start;
  }

  .circle-bar {
    margin-bottom: 5.3rem;
  }

  .meeting-card {
    margin-bottom: 3.2rem;
    width: 32rem;
    min-height: 19rem;
    height: 19rem;
  }

  &__quote-wrapper {
    padding: 2.4rem 3.2rem;
    border: 1px solid $light_gray_3;
    border-radius: 3.2rem;
    width: 32rem;
    height: 19rem;
    min-height: 19rem;
  }

  &__quote-title {
    color: $dark_gray;
    margin-bottom: .2rem;
  }

  &__quote {
    color: $main;
    max-width: 25.6rem;
  }
}

.my-progress-empty-state {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex-grow: .5;
  justify-content: center;

  svg {
    margin-bottom: 3.2rem;
  }

  &__text {
    max-width: 48.1rem;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: $dark_gray;
    text-align: center;
    margin-bottom: 3.2rem;
  }
}