.goal-description-card {
  width: 100%;
  padding: 2.4rem 3.2rem 2.6rem;
  border-radius: 3.2rem;
  position: relative;
  background-size: cover;
  background-position: center;
  border: 1px solid $white;
  transition: all .3s;
  min-height: 19.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    border: 1px solid $gray;
  }

  .dots-icon {
    stroke: $dark_gray;
  }

  &__dots_white {
    .dots-icon {
      stroke: $white;
    }
  }

  .link-icon {
    transition: all .2s;
  }

  &__title {
    max-width: 44.7rem;
    margin-bottom: 1.4rem;
  }

  &__context-menu {
    height: 3.2rem;
    width: 3.2rem;
    border-radius: .6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    position: absolute;
    right: 2.8rem;
    top: 2rem;

    &_active {
      background-color: $white;
    }

    .context-menu {
      position: absolute;
      bottom: -.6rem;
      right: 0;
      transform: translateY(100%);
      z-index: 1;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }

    &:hover {
      background-color: $white;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__category {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: $dark_gray;
    display: inline-block;
    margin-bottom: .4rem;

    span {
      text-transform: capitalize;
    }
  }

  &__update-progress-btn {
    position: absolute;
    top: 2.2rem;
    right: 3.2rem;
    transition: all .3s;
    opacity: 0;

    .primary-button {
      height: 2.9rem;
      font-size: 1.2rem;
      line-height: 1.7rem;
      font-weight: 500;
      padding: .6rem 1.2rem .7rem;

      &:hover {
        opacity: 1;
        box-shadow: 0px 8px 15px rgba($color: #000, $alpha: .1);
      }
    }

    &_moved {
      right: 7.2rem;
    }
  }

  &__link {
    max-width: 19.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $main;
    font-size: 1.3rem;
    line-height: 1.8rem;
    transition: all .3s;
    display: inline-block;
    align-items: center;
    font-weight: 500;
    margin-bottom: 2.4rem;

    svg {
      margin-right: 1rem;
    }

    &:hover {
      color: $yellow;

      .link-icon {
        fill: $yellow;
      }
    }
  }

  &:hover {
    .goal-description-card__update-progress-btn {
      opacity: 1;
    }
  }
}

.card {
  &-yellow, &-brown, &-violet, &-light_blue, &-pink, &-opal, &-slate_gray {
     .goal-description-card__category {
      color: $main;
      opacity: .5;
    }
  }

  &-yellow {
    .link-icon {
      fill: $main;
      opacity: .5;
    }
  }

  &-yellow, &-pink, &-opal {
    .progress-bar__external-label {
      color: $white;
    }
  }

  &-yellow, &-sand, &-opal{
    .progress-bar__lines {
      background-color: rgba($color: #fff, $alpha: .2);
    }
  }

  &-yellow, &-opal {
    .goal-description-card__context-menu {
      background-color: transparent;

      &:hover, &_active {
        background-color: rgba($color: #fff, $alpha: .1);
      }
    }
  }

  &-purple,&-violet, &-light_blue, &-pink, &-slate_gray {
    .link-icon {
      fill: $white;
      opacity: .5;
    }

    .dots-icon {
      stroke: $white;
      opacity: .5;
    }
  }

  &-pink, &-opal{
    .progress-bar__total-label {
      color: $main;
    }
  }

  &-yellow, &-pink {
    .goal-description-card__update-progress-btn {
      .primary-button {
        background-color: $main;
        color: $white;
      }
    }
  }

  &-brown, &-light_blue, &-slate_gray {
    .progress-bar__external {
     background-color: $white; 
    }
  }

  &-yellow, &-pink, &-opal {
    .progress-bar__external {
     background-color: $main; 
    }

    .progress-bar__total {
      background-color: $white;
    }
  }
}

.goal-description-card-bg, .card-brown, .card-pink, .card-purple, .card-violet, .card-light_blue, .card-slate_gray {
  .progress-bar__progresses, .progress-bar__label {
    color: $white;
  }

  .progress-bar__lines {
    background-color: rgba($color: #fff, $alpha: .2);
  }

  .goal-description-card__category,
  .goal-description-card__title,
  .goal-description-card__link  {
    color: $white;
  }

  .goal-description-card__link  {
    &:hover {
      color: $yellow;
    }
  }

  .goal-description-card__context-menu {
    background-color: transparent;

    &:hover, &_active {
      background-color: rgba($color: #fff, $alpha: .1);
    }
  }
}

.card-yellow, .card-opal {
  .goal-description-card__link {
    &:hover {
      color: $white;
      
      .link-icon {
        fill: $white;
        opacity: 1;
      }
    }
  }
}

.card-pink {
  .goal-description-card__link {
    &:hover {
      color: $main;
      
      .link-icon {
        fill: $main;
        opacity: 1;
      }
    }
  }
}

.goal-description-card-bg {
  .progress-bar__external-label {
    color: $yellow;
  }

  .progress-bar__total-label {
    color: $white;
  }

  .goal-description-card__category {
    color: $white;
    opacity: 1;
  }

  .goal-description-card__link {
    color: $white!important;

    &:hover {
      color: $yellow!important;
      
      .link-icon {
        fill: $yellow;
        opacity: 1;
      }
    }
  }
}

.goal-card-skeleton {
  height: 19rem;
  background-color: $light_gray;
  border-radius: 3.2rem;
  padding: 2.9rem 3.2rem 2.8rem;

  &__label {
    margin-bottom: 1.3rem;
    border-radius: 1.2rem;
    overflow: hidden;
    width: fit-content;
  }

  &__title {
    margin-bottom: 2.4rem;
    border-radius: 1.4rem;
    overflow: hidden;
    width: fit-content;
  }

  &__link {
    border-radius: 1.2rem;
    overflow: hidden;
    width: fit-content;
    margin-bottom: 3rem;
  }

  &__progresses {
    display: flex;
    justify-content: space-between;
  }

  &__progress {
    border-radius: 1.2rem;
    overflow: hidden;
    width: fit-content;
    margin-bottom: 1.1rem;
  }

  &__progress-bar {
    border-radius: 2rem;
    overflow: hidden;
    height: auto;
  }
}

.goal-description-card-bg {
  .progress-bar__external {
    background-color: $white;
  }
}

.goal-description-card-completed {
  border: 1px solid rgba(151, 217, 10, 0.3);
  background-color: $white!important;

  .link-icon {
    fill: $dark_gray;
    opacity: 1;
  }

  .progress-bar__external, .progress-bar__total {
    background-color: $green_text;
  }

  .progress-bar__external-label {
    display: none;
  }

  .progress-bar__total-label {
    color: $green_text;
  }

  .goal-description-card__title {
    color: $green_text;
  }

  .goal-description-card__category {
    color: $dark_gray;
    opacity: 1;
  }

  .goal-description-card__link {
    color: $main;

    &:hover {
      color: $green_text!important;
      
      .link-icon {
        fill: $green_text;
        opacity: 1;
      }
    }
  }

  .goal-description-card__update-progress-btn {
    .primary-button {
      background-color: $yellow;
      color: $main;
    }
  }
}

.goal-description-card-depricated {
  .progress-bar__external-label {
    display: none;
  }
}