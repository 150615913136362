.auth-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $light_gray;
  @include flex(row, center, center);

  &__in {
    @include flex(row, stretch, flex-start);
    max-width: 1024px;
    margin: auto;
    min-height: 64rem;
    border-radius: 2.4rem;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
    background-color: $white;

    .input {
      width: 24.8rem;
    }
  }

  &__logo {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -11px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $yellow;
    }
  }

  &__info {
    background-color: $main;
    color: $white;
    width: 43%;
    padding: 120px 80px 0 80px;
    @include flex(column, flex-start, flex-start);

    &-subtitle {
      line-height: 3.6rem;
      margin-top: 5.6rem;
      font-weight: 400;
    }

    &-text {
      margin-top: 2.4rem;
      color: $dark_gray;
    }
  }

  &__logo {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 400;

    .bold {
      font-weight: 800;
    }
  }

  &__form {
    width: 57%;
    @include flex(row, flex-start, center);
    padding-top: 120px;

    &-in {
      @include flex(column, flex-start, flex-start);
    }

    &-text {
      h3+p {
        margin-top: 1.2rem;
        max-width: 25rem;
      }

      margin-bottom: 3.6rem;
    }
  }
}