.upload-photo {

  &__label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: $yellow;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 600;

    span {
      margin-top: .6rem;
    }
  }

  &__preview {
    height: 8.8rem;
    width: 8.8rem;
    background-color: #ebebeb;
    border-radius: 50%;
    margin-right: 1.7rem;
  }

  &__input {
    display: none;
  }

  &__cropper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: $main, $alpha: 0.5);
    z-index: 12;
  }

  &__crop-container {
    background-color: $light_gray_2;
    border-radius: 3.2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4.8rem 12.7rem;
  }

  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 50%;
  }

  .cropper-view-box {
    outline: none;
  }

  .primary-button {
    margin-right: 1.6rem;
  }

  &__buttons {
    margin-top: 3rem;
  }
}