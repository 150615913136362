@mixin flex($direction:row, $align:initial, $justify:initial) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin statisctis-width {
  width: $statistics_width_sm;

  @media screen and #{$bp-notebook} {
    width: $statistics_width_lg;
  }
}

@mixin main-width {
  width: calc(100vw - #{$sidebar_width} - #{$statistics_width_lg});

  @media screen and #{$bp-notebook} {
    width: calc(100vw - #{$statistics_width_lg} - #{$closed_sidebar__width});
  }

  @media screen and (min-width: 1650px){
    width: 100vw - 25vw - 25vw;
  }
}


@mixin widthWithoutStatistic {
  width: calc(100% - #{$statistics_width_sm});

  @media screen and #{$bp-notebook} {
    width: calc(100% - #{$statistics_width_lg});
  }
}

@mixin headerWidth {
  width: calc(100vw - #{$statistics_width_sm} - #{$sidebar_width} - 12.8rem);

  @media screen and #{$bp-notebook} {
    width: calc(100vw - #{$statistics_width_lg} - #{$closed_sidebar__width} - 12.8rem);
  }
}