.completion-note {
  display: flex;
  width: 100%;

  .editorjs__editor_editable {
    padding-left: 0;
  }

  &__photo {
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    margin-right: 1.6rem;
  }

  &__body {
    background-color: $light_gray;
    border-radius: 2rem;
    padding: 1.6rem;
    flex-grow: 1;
  }

  &__header {
    display: flex;
    margin-bottom: .6rem;
    justify-content: space-between;
  }

  &__author {
    color: $main;
    font-weight: 600;
    margin-right: 1.2rem;
  }

  &__check {
    margin-right: .6rem;
    width: 1.6rem;
    height: 1.6rem;
  }

  &__completed-at {
    color: $dark_gray;
  }

  &__text {
    max-width: 41.9rem;
  }

  &__edit {
    color: $dark_gray;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__link {
    margin-top: 1.4rem;
    display: inline-flex;
    align-items: center;

    p {
      font-size: 1.3rem;
      line-height: 1.8rem;
      color: $main;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 16.6rem;
      transition: all .2s;
    }

    svg {
      margin-right: 1rem;
    }

    .link-icon {
      transition: all .2s;
    }

    &:hover {
      p {
        color: $yellow;
      }

      .link-icon {
        fill: $yellow;
      }
    }
  }

  &__image {
    width: 24rem;
    height: 18rem;
    border-radius: 1.6rem;
    display: block;
    margin-top: 2.8rem;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__zoom-icon {
    position: absolute;
    top: 2rem;
    right: 1.6rem;
    cursor: pointer;
  }
}