.meetings-calendar-monthly {
  &__header {
    display: flex;
  }

  &__weekday {
    width: 14.28%;
    border-right: 1px solid $gray;
    display: flex;
    justify-content: center;
    padding-bottom: 3.2rem;
    font-weight: 600;
    color: $dark_gray;

    &:last-of-type {
      border: none;
    }
  }

  &__date {
    width: 14.28%;
    height: 11.2rem;
    color: $main;
    padding-top: .8rem;
    padding-left: .8rem;
    padding-right: .8rem;
    border: 1px solid $gray;
    border-left: none;
    border-bottom: none;

    &:nth-of-type(7n){
      border-right: none;
    }
  }

  &__date_out {
    @extend .meetings-calendar-monthly__date;
    color: $dark_gray;
  }

  &__date-label {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-left: 1.2rem;
    margin-bottom: .8rem;
  }

  &__date-label_today {
    @extend .meetings-calendar-monthly__date-label;
    background-color: $yellow;
    padding: 0 1.3rem;
    border-radius: 50%;
    display: inline-block;
  }

  &__calendar {
    display: flex;
    flex-wrap: wrap;
  }

  &__month {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  &__event {
    background-color: rgba(250, 187, 24, .3);
    display: flex;
    justify-content: center;
    border-radius: .8rem;
    padding: 0 1.6rem 0 1.1rem;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    p {
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span {
      font-weight: 600;
    }
  }

  &__event_more {
    @extend .meetings-calendar-monthly__event;
    font-size: 1.3rem;
    line-height: 1.8rem;

    span {
       white-space: nowrap;
    }
  }
}

.meetings-calendar-header {
  margin-bottom: 6.4rem;

   &__title {
    display: flex;
    margin-bottom: 3.2rem;

    h1 {
      margin-right: 3.2rem;
    }

    span {
      color: $dark_gray;
    }
  }

  &__selectors {
    display: flex;
  }

  .toggler {
    max-width: 16.2rem;
    margin-right: 1.6rem;
  }
}