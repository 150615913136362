.select {
  max-width: 25rem;

  &:hover &__arrow {
    stroke: $main;
  }

  &__arrow {
    stroke: $dark_gray;
    transition: all .3s;
    transform: rotate(-90deg);
  }

  &__label-prompt {
    color: $dark_gray;
  }

  &,
  &-active {
    min-width: 16rem;
    height: 4rem;
    background-color: $light_gray;
    border-radius: $selector_radius;
    color: $main;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 1.6rem;
    cursor: pointer;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: calc(100% - 20px);
    }
  }

  &-active {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $light_gray;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    z-index: 2;
    max-height: 20rem;
    overflow-y: auto;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
    border-top: 1px solid #E1E1E1;

    &_multiple {
      li {
        padding-left: 4rem;
      }

      .select__option_selected {
        position: relative;

        &:after {
          position: absolute;
          content: '';
          background-image: url('../../assets/images/checkmark.svg');
          background-repeat: no-repeat;
          background-position: center;
          width: 1rem;
          height: .9rem;
          top: calc(50% - 0.5rem);
          left: 1.5rem;
        }
      }
    }
  }

  &__options--hidden {
    display: none;
  }

  &__option {
    padding: 1rem 1.6rem;
    font-size: 1.4rem;
    line-height: 2.1rem;
    cursor: pointer;

    &_selected {
      font-weight: 700;
    }

    &:hover {
      background-color: $light_gray_3;
    }

    &:last-of-type {
      margin-bottom: 1rem;
    }
  }

  &-active {
    border-color: $light_gray_3;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:hover .select__arrow {
      stroke: $main;
    }

    .select__arrow {
      transition: all .3s;
      transform: rotate(90deg);
    }
  }

  & span,
  &-active span {
    margin-bottom: 0;
  }

  &_disabled {
    @extend .select;
    width: 100%;
    max-width: 60.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $dark_gray;
    background-color: white;
    border: .1rem solid $gray;
    position: relative;
    height: $input_max_height;
    cursor: default;

    &:after {
      width: 1rem;
      height: 1rem;
      content: '';
      position: absolute;
      top: calc(50% - .5rem);
      right: 1.6rem;
      background-image: url('../../assets/images/chevron-down.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}