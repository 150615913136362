.editorjs {
  width: 100%;

  h1 {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  h2 {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }

  h3 {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }

  h1,
  h2,
  h3 {
    padding: 0;
    margin: 0;
  }

  &__editor {
    transition: max-height .3s ease-in-out;
    position: relative;

    &_editable {
      padding-left: 1.3rem;
    }

    &_overflow {
      overflow: hidden;
    }
  }

  &__toggler {
    @include flex(row, center, flex-start);
    margin-top: 2.4rem;
    color: $yellow;
    cursor: pointer;

    svg {
      margin-left: .8rem;
      margin-bottom: 1px;
      transition: all .3s ease-in-out;
    }

    &-icon {
      &_rotated {
        transform: rotate(180deg);
      }
    }
  }

  &__blur {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0920245) 0%, #FFFFFF 100%);
    z-index: 2;
  }

  &__skeleton {
    margin-bottom: 3.2rem;
  }

  //  EDITOR JS STYLES

  // rigth trigger 
  .codex-editor--narrow .ce-toolbar__actions {
    right: 0 !important;
  }

  .codex-editor--narrow .ce-toolbar__plus {
    @media (min-width: 651px) {
      left: -34px;
    }
  }

  .codex-editor__loader {
    display: none !important;
  }

  .codex-editor--empty {
    height: 100%;
  }

  .codex-editor__redactor {
    height: 100%;
  }

  .ce-block__content {
    margin: 0;
  }

  .cdx-marker {
    background-color: $light_yellow;
  }

  .cdx-checklist {
    .cdx-checklist__item--checked .cdx-checklist__item-checkbox {
      background: $yellow;
      border-color: $yellow;
    }

    &__item {
      &-checkbox {
        &:hover {
          border-color: $yellow;
        }

        // checkmark
        &::after {
          top: 0.5rem;
          left: 0.4rem;
          border-color: $main;
        }
      }
    }
  }
}

.text-editor-skeleton {
  &__title {
    display: block !important;
    border-radius: 1.4rem !important;
    margin-bottom: 2.2rem;
  }

  &__text {
    border-radius: 1.2rem !important;
    display: block !important;
    margin-bottom: 1rem;

    &_bottom {
      @extend .text-editor-skeleton__text;
      margin-bottom: 3.6rem;
    }
  }

  &__list-title {
    border-radius: 1.4rem !important;
    margin-bottom: 2.2rem;
  }

  &__list-point {
    margin-right: .6rem;
  }

  &__list-text {
    border-radius: 1.2rem !important;
  }

  &__list-item:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &__list {
    margin-bottom: 6.1rem;
  }

  &__bottom-text {
    border-radius: 1.4rem !important;
  }
}