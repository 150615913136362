.meeting-form-view {
  min-height: 100vh;
  @include flex(column, flex-start, flex-start);

  &__title {
    margin-bottom: 4.8rem;
  }

  &__in {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    @include flex(column, flex-start, flex-start);
  }
}

.meeting-form {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  max-width: 64rem;
  grid-auto-rows: min-content;
  align-content: space-between;
  flex: 1;

  &__in {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: start;
    grid-auto-rows: min-content;
    row-gap: 3.6rem;
  }

  &__field {
    display: grid;
    grid-template-columns: 24.8rem;
    column-gap: 1.6rem;
    grid-auto-rows: min-content;

    &:first-child {
      grid-template-rows: auto 4.8rem;
      grid-template-columns: 10.8rem 12.4rem 10.8rem;

      span {
        grid-column: span 3;
        align-self: end;
        margin-bottom: .8rem;
      }
    }

    &:nth-child(2) {
      $select_block_width: 24.8rem;
      grid-template-rows: auto 4.8rem;
      grid-template-columns: repeat(2, calc(($select_block_width - 1.6rem) / 2));
      grid-auto-flow: column;

      span {
        grid-column: span 1;
        align-self: end;
        margin-bottom: .8rem;
      }
    }

    &:last-child {
      grid-template-columns: auto;
    }

    textarea {
      resize: none;
      min-height: 9.6rem;
      min-width: 100%;
    }

    .select,
    .select-active {
      min-width: auto;
      height: $input_max_height;
    }
  }
}

.meeting-form-skeleton {
  @extend .meeting-form;

  &__in {
    @extend .meeting-form__in;
  }

  &__field {
    @extend .meeting-form__field;

    .input-skeleton {
      width: 100%;
    }
  }
}