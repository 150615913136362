.gray-square-button {
  width: 4rem;
  height: 4rem;
  background-color: $light_gray;
  border-radius: 1.2rem;
  outline: none;

  &:hover {
    background-color: darken($light_gray, 3%);
  }
}