.gray-circle-close-button {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 100%;
  background-color: $light_gray;
  position: relative;
  cursor: pointer;
  transition: background-color .3s ease;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
  }

  &:hover {
    background-color: darken($light_gray, 10%);
  }

  &__dark {
    @extend .gray-circle-close-button;
    background-color: $gray;

    &:hover {
      background-color: darken($gray, 10%);
    }

    svg {
      fill: $main
    }
  }
}