.person-progress-card {
  width: 100%;
  display: flex;
  padding: 2.4rem;
  background-color: $white;
  border-radius: 2.4rem;
  margin-bottom: 2.4rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);

  &_red {
    .progress-bar__total, .progress-bar__external {
      background-color: $accent;
    }
  }

  &_green {
    .progress-bar__total, .progress-bar__external {
      background-color: $green_line;
    }
  }

  &_yellow {
    .progress-bar__total, .progress-bar__external {
      background-color: $yellow;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__photo {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.1rem;

    div {
      width: 100%;
      height: 100%;
      background-color: #fafafa;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
    }
  }

  &__info {
    width: 80%;
    padding-top: .7rem;
  }

  &__name {
    font-weight: 600;
    display: block;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 2.1rem;
  }

  &__progress {
    margin-bottom: .5rem;

    p {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__weekly {
    font-weight: 600;
  }
}