.auth-form {
  &__field {
    @include flex(column, flex-start, flex-start);

    &:not(:first-of-type) {
      margin-top: 3.2rem;
    }

    &:first-child+.auth-form__controls {
      margin-top: 9.1rem;
    }

    &-error {
      color: $accent;
    }
  }

  &__controls {
    margin-top: 3.6rem;
    @include flex(row, center, space-between);
  }

  &__forgot {
    cursor: pointer;
    font-weight: 500;
  }
}