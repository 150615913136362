.meeting-date {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .dots-icon {
    stroke: $dark_gray;
  }

  &__day {
    color: $dark_gray;
    margin-bottom: .3rem;
  }

  &__users-info {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__day_today {
    color: $yellow;
  }

  &__date-holder {
    width: 8%;
    // min-width: 8.5rem;
    border-right: 1px solid $light_gray_3;
  }

  &__content-holder {
    padding-left: 3.2rem;
    width: 92%;
  }

  &__date {
    font-size: 3.2rem;
    line-height: 3.757rem;
    font-weight: 500;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__date_today {
    color: $yellow;
  }

  &__meeting {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.4rem;
  }

  &__meeting:last-of-type {
    margin-bottom: 0;
  }

  &__context-menu {
    height: 3.2rem;
    width: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .6rem;
    transition: all .3s;
    cursor: pointer;
    position: relative;
    margin-left: 2.4rem;

    &_active {
      background-color: $white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }

     .context-menu {
      position: absolute;
      bottom: -.6rem;
      right: 0;
      transform: translateY(100%);
      z-index: 1;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }

    &:hover {
      background-color: $white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }
  }

  .remove-icon {
    position: relative;
    margin-top: 1.6rem;

    &:hover {
      background-color: rgba(250, 105, 24, .2);

      .remove-icon {
        stroke: $accent;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $gray;
      transform: translateY(-.8rem) scaleX(1.2);
    }
  }

  &__employee {
    padding: 1.4rem 1.6rem;
    background-color: $light_gray;
    border-radius: 1.2rem;
    max-width: 31.5rem;
    margin-right: 2rem;
    transition: all .3s;

    &:hover {
      background-color: $gray;
    }

    span {
      font-weight: 600;
    }
  }

  &__reviewer {
    color: $dark_gray;
    font-size: 1.4rem;
    line-height: 2.1rem;
    transition: all .3s;

    &:hover {
      color: $main;
    }
  }

  &__clock-icon {
    margin-right: .8rem;
  }

  &__time {
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    width: 16.8rem;

    .clock-icon {
      fill: $gray;
    }

    span {
      margin: 0 .3rem;
    }
  }

  &__duration {
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    width: 6.6rem;
    margin-left: 7rem;
  }

  &__duration-icon {
    margin-right: .8rem;
  }
}