.groups {
  min-height: 100vh;

  width: 64rem;

  @media screen and (min-width: 1650px){
    width: 80rem;
  }

  .header-tabs {
    margin-bottom: 4.6rem;
  }

  &__empty-state {
    width: 64rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      margin-top: 14rem;
    }

    p {
      font-size: 2.4rem;
      line-height: 3.6rem;
      color: $dark_gray;
      text-align: center;
      font-weight: 500;
    }
  }

  &__list {
    max-width: 80rem;
  }

  &__group:not(:last-of-type) {
    margin-bottom: 3.2rem;
  }
}

