$size: 6px;
$time: 3s;

.linear-preloader {
  background-color: transparent;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: flex-start;
  z-index: 100000;

  &__element {
    height: $size;
    width: 100%;

    &::before {
      content: '';
      display: block;
      background-color: $yellow;
      height: $size;
      width: 0;
      animation: getWidth $time ease-in infinite;
    }
  }
}

@keyframes getWidth {
  100% {
    width: 100%;
  }
}