.search-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  svg {
    margin-bottom: 3.2rem;
  }

  p {
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: $dark_gray;
    text-align: center;
    max-width: 38.3rem;
    font-weight: 500;
  }
}