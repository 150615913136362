.search {
  height: 4rem;
  max-width: 4rem;
  min-width: 4rem;
  border-radius: $selector_radius;
  background-color: $light_gray;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: all .4s;
  border: 1px solid $light-gray;
  position: relative;
  z-index: 5;

  .search-icon {
    transition: all .2s;
  }

  &__icon {
    transform: translateX(.7rem);

    &:hover {
      .search-icon {
        fill: $main;
        stroke: $main;
      }
    }
  }
}

.search-active {
  height: 4rem;
  border-radius: $selector_radius;
  max-width: 19rem;
  min-width: 19rem;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: all .4s;
  border: 1px solid $gray;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
  animation: .3s openSearch;
  position: relative;
  z-index: 10;

  &__icon {
    transform: translateX(.7rem);
  }

  &__input {
    margin-left: 1.1rem;
    border: none;
    outline: none;
    font-size: 1.4rem;
    font-family: $raleway;
    font-weight: 500;
    color: $main;
    width: 83%;
    padding: 0 5px;
  }

  &__prompt {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }
}

@keyframes openSearch {
  0% {
    max-width: 4rem;
  }

  100% {
    max-width: 19rem;
  }
}