.group-details {
  @include main-width;
}

.group-details-holder {
  display: flex;
}

.group-details-header {
  margin-bottom: 3.2rem;

  &__title {
    margin-bottom: .6rem;
  }

  &__goals-amount {
    color: $dark_gray;
    margin-bottom: 3.2rem;
  }
}

.group-details-sidebar {
  width: $statistics_width_lg;
  display: flex;
  flex-direction: column;
  padding-right: 6.4rem;
  padding-top: 14.3rem;
  padding-bottom: 4.8rem;
  position: relative;
  height: 100%;

  &__skeleton {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
  }

  &__photo-skeleton {
    margin-right: 1.2rem;
  }

  &__edit-group {
   width: 10.9rem;
   position: absolute;
   top: 4.8rem;
   right: 6.4rem;
   font-weight: 500;
  }

  h3 {
    margin-bottom: 4.2rem;
  }

  &__user {
    a {
      display: flex;
      align-items: center;
      color: $main;
      font-weight: 500;
      transition: all .3s;
    }

    a:hover {
      color: $yellow;
    }
  }

  &__user:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }

  &__user-photo {
    margin-right: 1.2rem;
    height: 3.4rem;
    width: 3.4rem;
    background-color: $dark_gray;
    border-radius: 50%;
    background-size: cover;
  }
}

.group-details-goals {
  &__goal:not(:last-of-type){
    margin-bottom: 3.2rem;
  }
}

.group-details__empty-state {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: $dark_gray;
    font-weight: 500;
    margin-bottom: 3.2rem;
  }
}