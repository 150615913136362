.snackbar {
  position: fixed;
  right: 3.2rem;
  bottom: 3.2rem;
  max-width: 32rem;
  border-radius: 1.6rem;
  z-index: 12;

  &__body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    color: $white;
    font-weight: 500;
    text-align: center;
    padding: 2.4rem;
  }

  &__close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      fill: $white;
    }
  }

  &_success {
    background-color: $green_text;
  }

  &_error {
    background-color: $accent;
  }

  &_error &__body {
    padding-right: 4rem;
    text-align: left;
  }
}