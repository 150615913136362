.group-form-view {
  min-height: 100vh;
  @include flex(column, flex-start, flex-start);

  &__title {
    width: 100%;
    @include flex(row, center, space-between);

    button {
      color: $accent
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    @include flex(column, flex-start, flex-start);
    position: relative
  }
}

.group-form {
  @include flex(column, flex-start, space-between);
  margin-top: 4.8rem;
  width: 64rem;
  flex-grow: 1;

  &__check-icon_black {
    .color-check-icon {
      stroke: #090707;
    }
  }

  &__colors {
    width: 24.8rem;
    margin: 3.6rem 0;
  }

  &__color-pallete {
    display: flex;
    flex-wrap: wrap;
  }

  &__color {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 1.2rem;
    margin-right: 1.6rem;
    margin-top: 1.6rem;
    cursor: pointer;

    &:nth-of-type(5n){
      margin-right: 0;
    }
  }

  &__field {
    width: 24.8rem;

    .input {
      height: $input_max_height;
    }

    &+& {
      margin-top: 3.6rem;
    }
  }

  &__buttons {
    width: 100%;
    @include flex(row, flex-end, flex-start);
    flex-grow: 1;
  }
}

.group-form-skeleton {
  @extend .group-form;

  &__item {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 3.6rem;
  }

  &__input {
    &+& {
      margin-top: 1.6rem;
    }
  }
}