.people {
  @include main-width;

  &__employees {
    display: flex;
    flex-wrap: wrap;
  }

  &__epmloyee {
    width: 30%;
    margin-bottom: 4.5%;
    margin-right: 5%;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;

    h3 {
      margin-right: 2.4rem;
    }
  }

  &__reset {
    color: $accent;
    cursor: pointer;
  }
}

.people-header {
  position: relative;

  &__date {
    margin-right: 1.6rem;
  }

  &__groups {
    margin-right: 1.6rem;
    width: 20rem;
  }

  &__sort {
    margin-right: 1.6rem;
    width: 4rem;
  }

  &__selectors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.8rem;
    position: relative;
  }

  &__toggler {
    position: absolute;
    right: 0;
  }

  &__search {
    position: absolute;
    left: 46rem;
  }
}

.people-details {
  $people_details_pt: 3.2rem;
  @include main-width;
  padding-top: $people_details_pt;

  &__goal {
    margin-bottom: 3.2rem;
  }

  .remove-icon {
    position: relative;
    margin-top: 1.6rem;

    &:hover {
      background-color: rgba(250, 105, 24, .2);

      .remove-icon {
        stroke: $accent;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $gray;
      transform: translateY(-.8rem) scaleX(1.2);
    }
  }

  &__meetings {
    min-width: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.6rem;
    width: 100%;

    a {
      flex-shrink: 0;
    }

    &-wrapper {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .gray-button {
      color: $main;
      font-weight: 500;
    }
  }

  &__context-button {
    align-self: flex-start;
    padding-top: 4.8rem - $people_details_pt;
  }

  &__selectors {
    display: flex;
    margin-bottom: 3.2rem;

    .select-by-date {
      margin-right: 2.4rem;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__goal:not(:last-of-type) {
    margin-bottom: 3.2rem;
  }

  .circle-bar {
    padding: 0;
    width: 19.4rem;
    margin-bottom: 10rem;
  }
}

.people-sidebar-holder {
  height: 100%;
  background-color: $light_gray_2;
  position: fixed;
  right: 0;
  top: 0;

  @media screen and (min-width: 1650px){
    width: 25%;
  }
}

.people-sidebar {
  height: 100%;
  width: 38.4rem;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 4.6rem 3.7rem 0 3.7rem;

  &__title {
    margin-bottom: .8rem;
  }

  &__subtitle {
    color: $dark_gray;
    margin-bottom: 5rem;
  }

  &__meetings {
    @extend .my-progress-sidebar__meetings;

    &-card {
      .meeting-card+.meeting-card {
        margin-top: 3.2rem;
      }
    }
  }

  .circle-bar {
    margin: 0 auto;
    margin-bottom: 5.3rem;
  }

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

.people-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex-grow: .5;
  justify-content: center;

  svg {
    margin-bottom: 3.2rem;
  }

  &__text {
    max-width: 48.1rem;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: $dark_gray;
    text-align: center;
    margin-bottom: 3.2rem;
  }
}

.people-grid-view {
  width: 100%;
  padding-top: 4.8rem;

  &__employees {
    display: flex;
    flex-wrap: wrap;
  }

  &__epmloyee {
    width: 30%;
    margin-bottom: 4.5%;
    margin-right: 5%;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.people-search-state {
  margin-bottom: 3.2rem;

  div {
    display: flex;
    width: 100%;
    align-items: center;
  }

  &__reset {
    color: $accent;
    cursor: pointer;
    margin-left: 2.4rem;
    display: inline-block;
  }
}

.people-details-goals {
  width: 100%;
}

.people-details-header {
  display: flex;
  flex-direction: column;

  a {
    flex-shrink: 0;
  }

  &__user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.6rem;

    .primary-button {
      display: flex;
      align-items: center;
      white-space: nowrap;
      
      .context-menu-button__icon {
        transform: translateY(0);
      }

      .context-menu-button__icon_rotated {
        transform: translateY(0) rotate(-180deg);
      }
    }
  }

  &__selectors {
    display: flex;
    margin-bottom: 3.2rem;

    .select-by-date {
      margin-right: 2.4rem;
    }
  }
}

.people-details-goals-list {
  &__goal:not(:last-of-type) {
    margin-bottom: 3.2rem;
  }
}