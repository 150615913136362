.circle-bar {
  padding: 0 16%;
  position: relative;

  &__label {
    position: absolute;
    top: 23%;
    color: $dark_gray;
    margin-top: .4rem;
  }

  &__label_centered {
     transform: translateY(.3rem);
  }

  &__progress_centered {
    .CircularProgressbar-text {
      transform: translateY(.3rem);
    }
  }

  &__weekly-progress {
    position: absolute;
    bottom: 23%;
    font-weight: 500;
    color: $yellow;
    margin-bottom: .5rem;
  }
}