.header-profile {
  $avatar_size: 9.6rem;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &__avatar {
    width: $avatar_size;
    height: $avatar_size;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .avatar {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  &__body {
    margin-left: 3rem;
    padding-top: calc($avatar_size / 2) - 3.2rem;
    position: relative;

    p {
      margin-top: .4rem;
      color: $dark_gray;
    }
  }

  &__username {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__change-icon {
    // position: absolute;
    // bottom: 2.8rem;
    // left: calc(100% + 1.6rem);
    cursor: pointer;
    margin-left: 1.6rem;


    &:hover .pencil-icon {
      transition: fill .2s ease;
      fill: $yellow;
    }
  }
}