.progress-bar {
  width: 100%;

  &__values {
    display: flex;
    justify-content: space-between;
    margin-bottom: .8rem;
  }

  &__progresses {
    display: flex;

    p {
      font-size: 1.3rem;
      line-height: 1.8rem;
      font-weight: 500;
    }
  }

  &__external-label {
    color: $yellow;
  }

  &__label {
    color: $main;
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 500;
  }

  &__lines {
    height: .6rem;
    background-color: $light_gray_3;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
  }

  &__external {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $main;
    border-radius: 2rem;
  }

  &__total {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $yellow;
    border-radius: 2rem;
  }

  &__external-label {
    margin-right: .4rem;
  }
}