.select-by-date {
  width: 17.3rem;
  height: 4rem;
  background-color: $light_gray;
  border-radius: $selector_radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  color: $main;
  font-weight: 400;

  &__arrow, &__arrow--left {
    cursor: pointer;
    stroke: $dark_gray;
    transition: all .2s;

    &:hover {
      stroke: $main;
    }
  }

  &__arrow--left, &__arrow--left--disabled {
    transform: rotate(180deg);
    stroke: $dark_gray;
    transition: all .2s;
  }

  &__arrow--left--disabled, &__arrow--disabled {
    cursor: auto;
    opacity: 0.4;
  }
}