.edit-personal-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100vh;

  &__header {
    position: relative;
    width: 100%;

    span {
      font-weight: 500;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      margin-top: .5rem;
    }
  }

  &__remove-btn {
    font-weight: 600;
    color: $accent;
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__skeleton {
    margin-top: 3.6rem;
  }
}

.edit-personal-info-form {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 64rem;
  margin-top: 3.6rem;

  &__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 24.8rem;

    &+& {
      margin-top: 3.6rem;
    }

    input {
      @extend .text-main;
    }

    .select,
    .select-active {
      width: 24.8rem;
      height: $input_max_height;
    }

    .input {
      height: $input_max_height;
    }
  }

  &__radio {
    label {
      cursor: pointer;
      margin-top: 1.6rem;
    }

    input {
      margin-right: 1.2rem;
    }
  }
}

.edit-personal-info-skeleton {
  flex: 1;
  width: 64rem;
  @include flex(column, flex-start, flex-start);

  &__item {
    margin-top: 3.6rem;
  }
}