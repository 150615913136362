.provide-info {
  width: 100vw;
  height: 100vh;
  background-color: $light_gray_2;
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    border-radius: 2.4rem;
    overflow: hidden;
    display: flex;
    height: 60.2rem;
  }

  &__main {
    padding: 11rem 18.4rem 12.4rem 17.6rem;
    background-color: $white;
  }

  &__title {
    margin-bottom: 3.6rem;
    font-weight: 700;
  }

  &-form {
    display: flex;
    flex-direction: column;
  }

  &-form__label {
    margin-bottom: 3.2rem;
  }

  &-form__label:last-of-type {
    margin-bottom: 3.6rem;
  }

  &-form__button {
    max-width: 10rem;
  }

  &-form__name {
    display: flex;
    width: 100%;

    label {
      max-width: 11.6rem;
    }

    label:first-of-type {
      margin-right: 1.6rem;
    }
  }

  &__upload-photo {
    display: flex;
    flex-direction: column;
  }

  &__button {
    max-width: 10.6rem;
    margin-top: 13.4rem;
  }
}