.sticky-component {
   transition: box-shadow .3s;

   &__bottom-shadow {
    transition: box-shadow .3s;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
   }

   &__top-shadow {
     transition: box-shadow .3s;
     box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.07);
   }
}