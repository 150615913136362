.update-progress-form {
  .arrow-icon {
    transition: all .2s;
  }

  &__arrow {
    height: .9rem;
    width: .9rem;
    position: absolute;
    right: 1.3rem;
    bottom: 1.3rem;
    cursor: pointer;
    transform: rotate(-90deg);

    .arrow-icon {
      stroke: $dark_gray;
    }
  }

  &__progress-placeholder {
    margin-left: .3rem;
    color: $yellow;
    outline: none;

    &::selection {
      background-color: transparent;
    }
  }

  &__placeholder {
    position: absolute;
    color: $dark_gray;
    bottom: 1.25rem;
    left: 1.2rem;
    outline: none;

    &::selection {
      background-color: transparent;
    }
  }

  &__arrow_up {
    @extend .update-progress-form__arrow;
    transform: rotate(90deg);
    bottom: 2.5rem;
  }

  &__category {
    color: $dark_gray;
    margin-bottom: .4rem;
  }

  &__goal-title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    max-width: 32rem;
  }

  &__goal-info {
    min-width: 32rem;
    margin-right: 3.1rem;
  }

  &__goal-wrapper {
    display: flex;
  }

  &__input-holder {
    position: relative;
    width: 17.8rem;
    height: 4.8rem;
    margin-top: 1.5rem;
  }

  &__input {
    height: 4.8rem;
    text-align: right;
    padding-right: 3.4rem;
    font-size: 1.4rem;
    line-height: 2.1rem;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      display: none;
    }
  }
}