* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

html {
  font-size: 10px;
  font-feature-settings: 'pnum'on, 'lnum'on;
}

body {
  font-size: 1rem;
  font-family: $raleway;
}

ol,
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: $main;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

#root {
  height: 100%;
}

.hr {
  background-color: $gray;
  height: .1rem;
  width: 100%;
}

/* Paddings */

.padding-main {
  &_with-title {
    padding: 4.8rem 6.4rem 2.4rem 6.4rem;

    @media screen and #{$bp-notebook} {
      padding: 4.8rem 3.6rem 2.4rem 3.6rem;
    }
  }
}

.background-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: $main, $alpha: .5);
  z-index: 11;
}