.employee-card {
  background-color: $light_gray;
  border-radius: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 3.2rem 2.4rem;
  height: 100%;
  color: $main;

  .progress-bar__external {
    background-color: $yellow;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__photo_loading {
    margin-bottom: 2rem;
  }

  .avatar {
    margin-right: 0;
    margin-bottom: 1.4rem;
  }

  &__name {
    text-transform: capitalize;
    margin-bottom: .4rem;
    font-size: 1.6rem;
    white-space: break-spaces;
    text-align: center;
    line-height: 2.4rem;
    font-weight: 600;
    width: calc(100% + 3rem);

    &_loading {
      margin-bottom: .8rem;
    }
  }

  &__position {
    margin-bottom: 3.2rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: $dark_gray;
    text-align: center;

    &_loading {
      margin-bottom: 3.4rem;
    }
  }

  &__progress {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-left: .9rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: $dark_gray;
    }
  }
}