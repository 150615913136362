.activities-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  width: 50vw;

  @media screen and #{$bp-notebook} {
    width: 83vw;
  }

  &__bg {
    width: 43.6rem;
    height: 32.6rem;
    background-image: url('../../assets/images/no-activities.svg');
    margin-bottom: 5rem;
  }

  &__text {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.6rem;
    max-width: 28.9rem;
    text-align: center;
    color: $dark_gray;
  }
}

.activities {

  &-view {
    min-height: 100vh;

    width: 86.5rem;

    @media screen and #{$bp-notebook} {
      width: calc(100vw - #{$closed_sidebar__width});
    }

    &__header-select {
      max-width: 20.7rem;
      margin-top: 3.2rem;
    }

    &__content {
      max-width: 69.2rem;
    }

    &__content_empty {
      margin-top: 4.8rem;
    }

    &__req-trigger {
      background-color: $white;
      height: 1rem;
    }
  }
}

.activity-item {
  padding: 3.6rem 0;
  border-bottom: 1px solid $light_gray_3;

  &:last-of-type {
    border-bottom: none;
  }
}

.activity-header {
  display: flex;
  width: 100%;
  position: relative;

  &__employee-link {
    margin-right: .7rem;
    display: flex;
    align-items: center;
    transition: all .3s;

    &:hover {
      color: $yellow;
    }
  }

  &__point {
    position: absolute;
    left: -2rem;
    top: 1.8rem;
    background-color: $yellow;
    transform: translateY(-50%);
    height: .8rem;
    width: .8rem;
    border-radius: 50%;
  }

  &__button {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__photo {
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 50%;
    margin-right: 1.6rem;
  }

  &__photo-sm {
    height: 2.4rem;
    width: 2.4rem;
    margin: 0 .7rem;
    border-radius: 50%;
  }

  &__event-name {
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: 500;
    margin-bottom: .4rem;
    margin-top: .8rem;
    display: flex;
    align-items: center;
  }

  &__initiator-name {
    font-weight: 600;
    transition: color .2s;
    margin-right: .4rem;

    &:hover {
      color: $yellow;
    }
  }

  &__date {
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 500;
    color: $dark_gray;
  }
}

.activity-unread {
  .activity-header__photo {
    border: 2px solid $yellow;
    position: relative;
    display: inline-block;
  }
}

.activity-update-progress {
  &__event {
    margin-top: 2.4rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem 2.4rem;
    border: 1px solid $light_gray_3;
    border-radius: 1.6rem;
    margin-bottom: 1.2rem;
  }

  &__event:last-of-type {
    margin-bottom: 0;
  }

  &__goal {
    font-size: 1.6rem;
    line-height: 2.4rem;
    width: 65%;
  }

  &__progress {
    font-size: 1.3rem;
    line-height: 2.4rem;
    font-weight: 600;

    span {
      color: $yellow;
    }
  }
}

.activity-archived-goal {
   &__event {
    margin-top: 2.4rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem 2.4rem;
    border: 1px solid rgba(250, 105, 24, .3);
    border-radius: 1.6rem;
  }

  &__goal {
    font-size: 1.6rem;
    line-height: 2.4rem;
    width: 65%;
    color: $accent;
    text-decoration: line-through;
  }

  &__progress {
    font-size: 1.3rem;
    line-height: 2.4rem;
    font-weight: 600;
  }
}

.activity-completed-goal {
  &__event {
    margin-top: 2.4rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem 2.4rem;
    border: 1px solid rgba(139, 185, 7, .3);
    border-radius: 1.6rem;
  }

  &__goal {
    font-size: 1.6rem;
    line-height: 2.4rem;
    width: 65%;
    color: $green_text;
  }

  &__progress {
    font-size: 1.3rem;
    line-height: 2.4rem;
    font-weight: 600;
  }
}
