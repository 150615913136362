.progress-block {
  padding: 0 3.2rem;
  border-radius: 2.4rem;
  background-color: $light_gray;
  height: 9.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &-completed {
    .progress-bar__external, .progress-bar__total {
      background-color: $green_text;
    }
  }

  .progress-bar__values {
    display: none;
  }

  .progress-bar__external {
    background-color: $yellow;
  }

  &__values {
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: space-between;
  }

  &__labels {
    display: flex;
    align-items: center;
  }

  &__label {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-right: 1.2rem;
  }

  &__progresses {
    display: flex;
  }

  &__external-label {
    font-size: 1.3rem;
    font-weight: 500;
    color: $yellow;
    margin-right: .5rem;
    line-height: 1.8rem;
  }

  &__external-label_hidden {
    display: none;
  }

  &__total-label {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  &__update {
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: $yellow;
    font-weight: 600;
    cursor: pointer;
  }
}

.progress-block_white {
  @extend .progress-block;
  background-color: $white;
  border: 1px solid $gray;

  .progress-bar__values {
    margin-bottom: 1.2rem
  }

  p {
    font-weight: 500;
  }
}