.input {
  width: 100%;
  padding: 1.4rem 1.6rem;
  border-radius: 1.2rem;
  background-color: $light_gray;
  outline: none;
  border: none;
  font-weight: 400;
  font-family: $raleway;
  @extend .text-main;

  &::placeholder {
    font-family: $raleway;
    font-weight: 400;
    color: $dark_gray;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}