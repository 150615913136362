@font-face {
  font-family: "Raleway";
  src: url('../../assets/fonts/Raleway-Bold.ttf') format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Raleway";
  src: url('../../assets/fonts/Raleway-ExtraBold.ttf') format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Raleway";
  src: url('../../assets/fonts/Raleway-Medium.ttf') format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Raleway";
  src: url('../../assets/fonts/Raleway-SemiBold.ttf') format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Raleway";
  src: url('../../assets/fonts/Raleway-Regular.ttf') format("truetype");
  font-weight: 400;
}