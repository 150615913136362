.popup {
  max-width: 48rem;
  min-height: 25.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
  border-radius: 3.2rem;
  padding: 4.8rem 7.2rem;

  &_title {
    text-align: center;

    p {
      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: 500;
      max-width: 36rem;
      display: inline-block;
    }
  }

   &__subtitle {
    font-size: 1.3rem;
    line-height: 2rem;
    color: $dark_gray;
    max-width: 22.2rem;
    display: inline-block;
    margin-top: 1.2rem;
  }

  &_buttons {
    display: flex;
    padding-top: 3.2rem;

    button+button {
      margin-left: 1.6rem;
    }
  }
}