.outlined-button {
  @extend .primary-button;
  border-color: $yellow;
  background-color: $white;

  &:hover {
    opacity: .8;
  }

  &:active {
    opacity: 1;
  }

  &:disabled {
    background-color: inherit;
  }

  &:disabled:hover {
    opacity: 1;
    opacity: 0.8;
  }
}