//media
$bp-notebook: "(max-width: 1366px)";

//sizes 
$statistics_width_sm: 38.4rem;
$sidebar_width: 28.8rem;
$statistics_width_lg: 38.4rem;
$closed_sidebar__width: 5.6rem;
$input_max_height: 4.8rem;


//colors
$white: #fff;
$yellow: #fabb18;
$light_yellow: #FCDD8B;
$light_brown: #644700;
$blue: #18b6fa;
$green_line: #97d90a;
$green_text: #8bb907;
$accent: #fa6918;
$main: #090707;
$dark_gray: #908e8e;
$gray: #e1e1e1;
$light_gray: #f7f7f7;
$light_gray_2: #fafafa;
$light_gray_3: #ebebeb;

//fonts
$raleway: 'Raleway';

//radiuses
$button_radius: 3.2rem;
$selector_radius: 1.2rem;