.form-popup {
  $padding: 4.8rem;

  min-width: 60rem;
  min-height: 30rem;
  max-height: calc(100vh - 5rem);
  overflow: auto;
  border-radius: 3.2rem;
  padding-bottom: 0;
  background-color: $white;
  position: relative;
  z-index: 12;
  overflow-x: hidden;

  &__in {
    padding: 0 $padding;
    width: 100%;
    @include flex(column, flex-start, flex-start);
  }

  &__title {
    padding-top: $padding;
    margin-bottom: 3.2rem;

    &_sticky {
      position: sticky;
      top: 0;
      padding-bottom: 2.6rem;
      padding-left: $padding;
      background-color: $white;
      min-width: 120%;
      z-index: 5;
      margin-left: -4.8rem;
      margin-bottom: .5rem;
      
      .form-popup__title {
        margin-bottom: 0rem;
      }
    }
  }

  &__content {
    width: 100%;
  }

  &__remove-btn {
    color: $accent;
    position: absolute;
    right: $padding;
  }

  &__controls {
    margin-top: 4.8rem !important;
    padding-bottom: $padding;
    position: relative;

    &_sticky {
      position: sticky;
      width: calc(100% + #{$padding} * 2);
      background-color: $white;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.03);
      padding: $padding;
      padding-top: 1.6rem;
      bottom: 0;
      margin-left: -$padding;
      z-index: 5;
    }

    button+button {
      margin-left: 1.6rem;
    }
  }
}