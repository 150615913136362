.bottom-form-buttons {
  width: 100%;
  margin-top: 3.6rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__separator {
    width: 100%;
    height: .1rem;
    background-color: $gray;
  }

  &__block {
    margin-top: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}