.toggler {
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem .5rem;
  position: relative;
  z-index: 3;
  width: 100%;

  &_default {
    background-color: $light_gray;
  }

  &_dark {
    background-color: $gray;
  }

  button {
    outline: none;
    @extend .text-main;
    font-family: $raleway;
    font-weight: 500;
    white-space: nowrap;
    padding: 0 1.1rem;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    min-width: 4rem;
    color: $dark_gray;
    background-color: transparent;
    z-index: 2;

    &:hover {
      color: $main;
      transition: all .3s ease;

      svg {
        fill: $main;
      }

      //TODO: Nikita K Hover for active background
      // background-color: rgba($color: $white, $alpha: .6);
      // box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
      // border-radius: 8px;
      // transition: all .3s ease;
    }

    &_active {
      color: $main;
      transition: color .3s ease .1s;

      svg {
        transition: fill .3s ease .1s;
        fill: $main;
      }
    }
  }

  &__active-bg {
    background-color: $white;
    height: 3rem;
    min-width: 4rem;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    position: absolute;
    top: .5rem;
    left: .5rem;
    z-index: 1;
    transition: left .3s ease;
  }
}