.provide-info-sidebar {
  width: 42.4rem;
  background-color: $main;
  padding: 11rem 0 22.3rem 8rem;

  &__title {
    margin-bottom: 5.6rem;
  }

  &__quote {
    color: $white;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 500;
    max-width: 26.4rem;
    margin-bottom: 2.4rem;
  }

  &__description {
    color: $dark_gray;
    max-width: 26.8rem;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }
}