$sidebar_item_mb: 3.9rem;
$laptop_sidebar_item_mb: 2.7rem;

.sidebar-holder {
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $light_gray_2;
  z-index: 10;

  @media screen and (min-width: 1650px) {
    width: 25%;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and #{$bp-notebook} {
    position: fixed;
  }
}

.sidebar {
  width: $sidebar_width;
  min-width: 24rem;
  z-index: 10;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and #{$bp-notebook} {
    position: fixed;
    background-color: $light_gray_2;
  }

  &__close-icon {
    position: absolute;
    top: 1.6rem;
    right: -3.2rem;
  }


  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__wrapper {
    padding: 0 5.6rem 5.6rem;
  }

  &__title {
    margin-bottom: 4.6rem;
    position: relative;
    transition: box-shadow .3s;
    padding: 5.6rem 0 2.4rem;
    display: flex;
    flex-direction: column;

    a:first-of-type {
      display: inline-block;
      margin-bottom: .8rem;
    }

    @media screen and #{$bp-notebook} {
      margin-bottom: 2.6rem;
    }
  }

  &__title_sticky {
    transition: box-shadow .3s;
    position: sticky;
    top: 0;
    background-color: $light_gray_2;
    margin: 0 -5.6rem;
    padding: 0 5.6rem;
  }

  &__link {
    transition: all .2s;

    &:hover {
      color: $yellow;
    }
  }

  &__user-name {
    font-weight: 400;
    color: $dark_gray;
    font-size: 1.4rem;
    line-height: 2.1rem;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    position: static;
    z-index: 15;
  }

  &__nav-list-item-link,
  &__nav-list-item-link--active {
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: 500;
    color: $dark-gray;
    transition: all .2s;

    &:hover {
      color: $main;

      svg {
        fill: $yellow;
      }
    }

    svg {
      transition: all .2s;
      margin-right: 1.2rem;
    }
  }

  &__nav-list-item-link--active {
    svg {
      fill: $yellow;
    }

    color: $main;
  }

  &__separator {
    width: 100%;
    margin-bottom: $sidebar_item_mb;
    height: 0.1rem;
    margin-top: $sidebar_item_mb;
    background-color: $light_gray_3;

    @media screen and #{$bp-notebook} {
      margin-bottom: $laptop_sidebar_item_mb;
      margin-top: $laptop_sidebar_item_mb;
    }
  }

  &__logout {
    display: inline-flex;
    font-weight: 500;
    color: $dark-gray;
    cursor: pointer;
    transition: all .2s;

    svg {
      margin-right: 1.2rem;
      transition: all .2s;
    }

    &:hover {
      color: $main;
    }

    &:hover svg {
      fill: $yellow;
    }
  }

  &__made-by {
    padding-bottom: 4.3rem;
    font-weight: 400;
    color: $dark_gray;
    left: 5.6rem;
    padding-left: 5.6rem;

    span {
      font-weight: 500;
    }
  }

  &__made-by_sticky {
    position: sticky;
    bottom: 0;
    background-color: $light_gray_2;
    padding-top: 3.9rem;
  }

  &__nav-list-item-activities-count {
    text-align: center;
    background-color: $yellow;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 1.1rem;
    line-height: 2rem;
    margin-left: auto;
  }

  &__nav-item_activity {
    display: flex;
    align-items: center;
  }

  &__nav-list-item {

    &:not(:last-of-type) {
      margin-bottom: $sidebar_item_mb;

      @media screen and #{$bp-notebook} {
        margin-bottom: $laptop_sidebar_item_mb;
      }
    }
  }

  &__icon-wrapper {
    width: $closed_sidebar__width;
    position: sticky;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    @include flex(row, center, center);
    background-color: $light_gray_2;
  }

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    @include flex(row, center, center);
    cursor: pointer;

    &:hover {
      svg {
        transition: all .3s ease;
        stroke: $main;
      }
    }
  }

  &__background {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: $main, $alpha: .5);
    z-index: 9;
  }
}