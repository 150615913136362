.goal-form-view {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__title {
    width: 100%;
    @include flex(row, center, space-between)
  }

  &__remove-btn {
    color: $accent;
  }
}

.goal-form {
  width: 52.8rem;

  &__field {
    width: 100%;
    @include flex(row, center, flex-start);

    label {
      width: calc(50% - 3.2rem / 2);

      &+label {
        margin-left: auto;
      }
    }

    .select,
    .select-active,
    input {
      height: 4.8rem;
    }

    &+& {
      margin-top: 3.6rem;
    }

    &+.goal-form__field-prompt {
      margin-top: 1.2rem;
    }

    &:first-child {
      flex-direction: column;
    }

    &_multiple {
      flex-wrap: wrap;

      label.label {
        &:nth-child(3) {
          order: 1;
          margin-top: 3.6rem;
        }
      }

      .goal-form-private-field {
        order: 2;
        width: calc(50% - 3.2rem / 2);
        margin-top: 3.6rem;
        margin-left: auto;
      }
    }

    &-completion {
      label {
        @include flex(row, center, flex-start);
        cursor: pointer;

        input {
          width: 1.6rem !important;
          height: 1.6rem;
          margin-right: 1.2rem;

          &:after {
            top: 0 !important;
            left: 4px !important;
          }
        }
      }
    }
  }

  &-personal-field__month {
    margin-left: 0;
  }

  &-private-field {
    @include flex(column, flex-start, center);

    .label {
      margin-bottom: .8rem;
    }

    &__controls {
      height: 4.8rem;
      @include flex(row, center, flex-start);

      label {
        width: auto;
        cursor: pointer;

        input {
          height: 2.1rem;
          margin-right: 1.2rem;
        }

        &+label {
          margin-left: 2.4rem;
        }
      }
    }
  }

  &__input {
    position: relative;
    max-height: 4.8rem;

    &_file {
      cursor: pointer;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      width: calc(100% - 2rem);
    }
  }

  &__input-prompt {
    font-size: 1.4rem;
    line-height: 2rem;
    color: $dark_gray;
  }

  &__input-icon {
    position: absolute;
    top: 50%;
    right: 1.4rem;
    transform: translateY(-50%);
  }

  &__note {
    display: flex;
    width: 100%;
    min-height: 9.6rem;
    resize: none;
    margin-top: 1.6rem;
    @extend .text-main;
  }
}

// Skeleton
.goal-form-skeleton {
  &__input {
    width: 100%;

    .input-skeleton {
      width: 100%;
    }

    &+& {
      margin-left: 3.2rem;
    }
  }

  &__textarea {
    width: 100%;
  }

  .goal-form__field_multiple {
    &>div {
      width: calc(50% - 3.2rem / 2);

      &:nth-child(1),
      &:nth-child(3) {
        margin-right: auto;
      }

      &:nth-child(3) {
        order: 1;
        margin-top: 3.2rem;
      }

      &:nth-child(3),
      &:nth-child(2) {
        margin-left: 0;
        margin-top: 3.2rem;
      }
    }

    .goal-form-private-field {
      margin: 0;
    }
  }
}