.gray-button {
  background-color: $light_gray;
  border-radius: 32px;
  padding: 1rem 2rem;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 2.4rem;
  transition: all .2s;

  &:hover {
    background-color: $gray;
  }

  &:active {
    background-color: $light_gray;
  }
}