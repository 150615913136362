.activities-skeleton {
  &__update-progress {
    .profile-skeleton {
      margin-bottom: 2.8rem;
    }
  }

  &__progress {
    padding: 2.3rem 2.4rem 2.3rem 2.2rem;
    border: 1px solid $gray;
    border-radius: 1.6rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2rem;
  }

  &__progress_large {
    padding: 2.3rem 2.4rem 2.3rem 2.2rem;
    border: 1px solid $gray;
    border-radius: 1.6rem;
    margin-bottom: 3.6rem;
  }

  &__progress-large-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: .8rem;
  }

  &__progress-text {
    border-radius: 1.2rem!important;
  }
}

.profile-skeleton {
  display: flex;

  &__photo {
    margin-right: 1.6rem;
  }

  &__title {
    border-radius: 1.2rem!important;
    display: block!important;
    margin-bottom: 1rem;
  }

  &__subtitle {
     border-radius: 1.2rem!important;
  }
}

.profile-skeleton_border-top {
  padding: 3.6rem 0 4rem;
  border-top: 1px solid $light_gray_3;
}

.profile-skeleton_bordered {
  padding: 3.6rem 0 4rem;
  border-top: 1px solid $light_gray_3;
  border-bottom: 1px solid $light_gray_3;
}