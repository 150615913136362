// INPUT 
.input-skeleton {
  width: 24.8rem;

  &__wrapper {
    @extend .input;
    height: 4.8rem;
  }

  &__item {
    border-radius: 14px !important;
  }
}

// TEXTAREA

.textarea-skeleton {
  width: 100%;

  &__wrapper {
    @extend .input;
    width: 100%;
    height: 9.6rem;
  }

  &__item {
    border-radius: 14px !important;
  }
}