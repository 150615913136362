.add-new-employee {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.add-new-employee-form {
  flex: 1 1 auto;
  width: 64rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &+& {
      margin-top: 3.6rem;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &-controls {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .input {
        @extend .text-main;
        font-weight: 500;
        width: 24.8rem;
        max-height: $input_max_height;
      }
    }
  }

  &__radio-group {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input {
      margin-right: 1.2rem;
    }

    label {
      font-size: 1.4rem;
      line-height: 2.1rem;
      display: flex;
      cursor: pointer;

      &:first-child {
        margin: 0 2.4rem 0 3.2rem;
      }

      &:last-child {
        margin-right: 3.6rem;
      }
    }
  }

  .outlined-button {
    margin-top: 4.4rem;
  }
}