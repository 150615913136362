.statistics-holder {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $light_gray_2;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  
  @media screen and (min-width: 1650px){
    width: 25%;
  }
}

.statistics {
  @include statisctis-width;
  padding: 5.7rem 3.2rem 16.8rem 3.2rem;

  .toggler__item {
    width: 31%;
  }

  .toggler__active-bg {
    width: 30%!important;
  }

  &__circle-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__circle-wrapper {
    display: flex;
    justify-content: center;
  }

  .circle-bar {
    width: 19.4rem;
    padding: 0;
    margin: 0;
  }

  &__title {
    margin-bottom: .8rem;
  }

  &__text {
    color: $dark_gray;
    margin-bottom: 5.1rem;
  }

  &__selector {
    width: 100%;
    display: flex;
    border-radius: 1.2rem;
    justify-content: space-between;
    margin-top: 5.5rem;
    margin-bottom: 3.2rem;
  }

  &__image {
    width: 100%;
  }

  &__selector-item,
  &__selector-item--active {
    width: 32%;
    color: $dark-gray;
    border-radius: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;

    p {
      cursor: pointer;
    }
  }

  &__selector-item--active {
    background-color: $white;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
  }

  &__goals {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__goal {
    $width: 46%;
    width: $width;
    background-color: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    margin-bottom: 2.4rem;
    padding: 2.2rem 0 2.6rem 2.4rem;
    color: $dark_gray;

    div {
      display: flex;
    }

    &-label {
      margin-bottom: .9rem;
    }

    &-value {
      font-size: 2.8rem;
      line-height: 3.75rem;
      font-weight: 600;
      color: $main;
    }

    hr {
      border: none;
      width: .3rem;
      margin-right: 1.2rem;
      height: 2.6rem;
      transform: translateY(5px);
    }
  }
}