$dotWidth: 10px;
$dotHeigth: 10px;
$dotsGap: 5px;
$dotsColor: $white;

.loading-button {
  position: relative;
}

.dot-flashing {
  position: relative;
  width: $dotWidth;
  height: $dotHeigth;
  border-radius: 50%;
  background-color: $dotsColor;
  color: $dotsColor;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include flex(row, center, center);
    min-height: 2.1rem;
    min-width: 5rem;
  }

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -$dotHeigth - $dotsGap;
    width: $dotWidth;
    height: $dotHeigth;
    border-radius: 50%;
    background-color: $dotsColor;
    color: $dotsColor;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: $dotHeigth + $dotsGap;
    width: $dotWidth;
    height: $dotHeigth;
    border-radius: 50%;
    background-color: $dotsColor;
    color: $dotsColor;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $dotsColor;
  }

  50%,
  100% {
    background-color: #ebe6ff;
    opacity: .7;
  }
}