.meetings {
  padding-bottom: 13rem;
  width: calc(100vw - #{$sidebar_width});

  @media screen and #{$bp-notebook} {
    width: calc(100vw - #{$closed_sidebar__width});
  }

  @media screen and (min-width: 1650px){
    max-width: 118.4rem;
  }

  hr {
    height: .1rem;
    border: none;
    background-color: $gray;
    margin-bottom: 4.8rem;
  }

  &__empty-state {
    width: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 6rem;
      color: $dark_gray;
      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: 500;
      max-width: 29rem;
      text-align: center;
    }
  }
}

.meetings-header {
  &__selectors {
    margin-top: 2.8rem;
    display: flex;
  }

  &__select {
    margin-left: 1.6rem;
    min-width: 21.6rem;
    margin-bottom: 3.2rem;
  }
}

.meetings-dates {
  width: 100%;

  &__date:not(:last-of-type) {
    margin-bottom: 4.8rem;
  }
}

.meetings-dates-skeleton {
  margin-top: 4.9rem;

  &__body {
    @include flex(row, center, flex-start);

    &+& {
      margin-top: 2.5rem;

      .meeting-info__with-whom {
        height: fit-content;

        span {
          width: 24.6rem;
        }
      }
    }

    .meeting-info__with-whom {
      height: fit-content;
      padding: 1.6rem;
      background-color: $light_gray;
      border-radius: 1.2rem;
      margin-right: 2.4rem;

      span {
        width: 27.8rem;
      }

      &:hover {
        background-color: $light_gray;
      }
    }

    div:nth-child(2) {
      margin-right: auto;
    }
  }

  &__date {
    span {
      margin-top: 1.4rem;
    }
  }

  &__time {
    margin-left: 9.3rem;
    @include flex(row, center, flex-start);

    svg {
      margin-right: 1.2rem;
      fill: $gray
    }
  }

  &__duration {
    margin-left: 7.7rem;
    @include flex(row, center, flex-start);

    svg {
      margin-right: 1.2rem;
    }
  }

  span {
    border-radius: 1.4rem;
  }
}

.meetings-dates-skeleton {
  display: flex;

  &__date-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 2.4rem;
    border-right: 1px solid $light_gray_3;
    padding-bottom: 5.2rem;
    margin-right: 3.2rem;
  }

  &__date_first {
    margin-bottom: 1.4rem;
  }
}