.sorter {

  .sort-icon {
    transition: all .2s;

    &__arrow {
      transition: all .2s;
    }
  }

  &__icon {
    &:hover {
      .sort-icon {
        fill: $main;
      }

      .sort-icon__arrow {
        stroke: $main;
      }
    }
  }

  &__button {
    width: 4rem;
    height: 4rem;
    border-radius: $selector_radius;
    background-color: $light_gray;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: .8rem;
    position: relative;
  }

  &__options {
    z-index: 6;
    width: 24rem;
    border-radius: $selector_radius;
    background-color: $light_gray;
    padding: 1.2rem 0 1.6rem;
    position: absolute;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
  }

  &__placeholder {
    color: $dark_gray;
    padding: 0 1.6rem;
    margin-bottom: 1.2rem;
  }

  &__option {
    padding: 1rem 1.6rem;
    cursor: pointer;

    &:hover {
      background-color: $light_gray_3;
    }
  }
}