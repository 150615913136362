.context-menu {
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1.6rem;
  overflow: hidden;

  &__menu {
    background-color: $white;
    padding: .8rem;
    width: 25rem;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    z-index: 6;
  }

  &__menu-item {
    display: flex;
    padding: 1.4rem 1.6rem 1.3rem 1.6rem;
    align-items: center;
    border-radius: .9rem;
    color: $main;
    font-weight: 500;
    transition: all .2s;

    p {
      margin-left: 1.7rem;
    }

    &:hover {
      background-color: $light_gray;
    }
  }
}