.context-menu-button {
  position: relative;

  .context-menu {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(0, 110%);
    z-index: 10;
  }

  &__icon {
    margin-left: 1rem;
    transform: translateY(-2px);
    transition: all .2s;

    &_rotated {
      @extend .context-menu-button__icon;
      transform: rotate(-180deg) translateY(3px);
    }
    
    .chevron-down-icon {
      stroke: $main;
    }
  }
}